@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* color code */
.sketch-picker .flexbox-fix:nth-child(3) {
  padding-bottom: 4px;
}
.sketch-picker .flexbox-fix:nth-child(3) div:nth-child(5) {
  display: none !important;
}
.sketch-picker .flexbox-fix:nth-child(4) {
  display: none !important;
}
.sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(2) {
  display: none;
}

/* accordion code */
body { background-color: #f4f2f0; }
.html-text-styler-accordion {  
  background-color: #f4f2f0 !important; 
  box-shadow: 1px 2px 8px 1px rgb(0 0 0 / 20%) !important; 
  margin: 5px 0px;
  border-radius: 4px !important;
}
.html-text-styler-accordion:before { display: none; }
.html-text-styler-accordion .MuiAccordionSummary-content p { 
  font-size: 14px; 
  font-family: 'Raleway', sans-serif;
  font-weight: 600; 
}
.html-text-styler-accordion .MuiCollapse-wrapperInner, 
.html-text-styler-accordion.html-text-styler-subaccordion .html-text-styler-accordion .MuiCollapse-wrapperInner{
  background-color: #fff;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 1px 2px 8px 1px rgb(0 0 0 / 20%);
}
.html-text-styler-accordion.html-text-styler-subaccordion .MuiCollapse-wrapperInner {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}
.html-text-styler-accordion .MuiAccordionDetails-root, 
.html-text-styler-accordion.html-text-styler-subaccordion .html-text-styler-accordion .MuiAccordionDetails-root{
  padding: 5px 15px;
}
.html-text-styler-accordion.html-text-styler-subaccordion .MuiAccordionDetails-root {
  padding: 0px;
}
.html-text-styler-accordion .accordion-switch.html-text-styler-switch {
  position: absolute;
  right: 0px;
  top: 18px;
  margin: -12px 0px !important;
  visibility: visible;
}
.html-text-styler-accordion .MuiAccordionSummary-root {
  flex-direction: row-reverse;
}
/* form fields */
.html-text-styler-accordion .html-text-styler-disabled {
  opacity: 0.5;
}
.html-text-styler-input-field .MuiOutlinedInput-input {
  padding: 10px 10px;
  font-family: 'Raleway', sans-serif;
}
.html-text-styler-input-field {
  margin-bottom: 10px !important;
}
.html-text-styler-dropdown .MuiSelect-select {
  padding: 10px 10px;
  font-family: 'Raleway', sans-serif;
}
.html-text-styler-switch .MuiTypography-root {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 14px;
}
.html-text-styler-switch {
  margin: 10px 0px !important;
  justify-content: space-between !important;
  display: flex !important;
}
ul.MuiList-root li {
  font-family: 'Raleway', sans-serif;
}
.html-text-styler-radio-group-align .MuiRadio-root, 
.html-text-styler-checkbox-font-style .MuiCheckbox-root { display: none; }
.html-text-styler-radio-group-align.MuiFormGroup-root, 
.html-text-styler-checkbox-font-style.MuiFormControl-root .MuiFormGroup-root 
{ flex-direction: row; margin: 0px -5px; }
.html-text-styler-radio-group-align .MuiFormControlLabel-root,
.html-text-styler-checkbox-font-style .MuiFormControlLabel-root { 
  margin: 0px 5px; 
}
.html-text-styler-radio-group-align .MuiFormControlLabel-root .MuiTypography-root,
.html-text-styler-checkbox-font-style .MuiFormControlLabel-root .MuiTypography-root {
  line-height: normal;
  padding: 3px 3px 0px;
  border-radius: 4px;
  border: 1px solid black;
}
.html-text-styler-radio-group-align .MuiRadio-root.Mui-checked + .MuiTypography-root,
.html-text-styler-checkbox-font-style .MuiCheckbox-root.Mui-checked + .MuiTypography-root {
  background-color: #d1d1d1;
}
.html-text-styler-slider {
  margin-bottom: 10px;
  margin-left: 25px;
}  

.html-text-styler-input-field:after {
  font-family: 'Raleway', sans-serif;
  position: absolute;
  top: 50%;
  right: 28px;
  transform: translateY(-50%);
}
.html-text-styler-input-field.html-text-styler-input-field-perc:after {
  content: '%';
}
.html-text-styler-input-field.html-text-styler-input-field-px:after {
  content: 'px';
}
.html-text-styler-input-field {
 width:100px;
}
